import React, { useEffect } from 'react'
import styled from 'styled-components'
import "./base.css";
import "./style.css";
import axios from "axios";
import {stripe} from "./base";
import DonationFormV2 from "./components/DonationFormV2";
import useUrlParams from "../../utils/useURLParams";
import i18n, { supportedLang } from "../../i18n";
import { withNamespaces } from 'react-i18next';
import {urlProjectStripeConfig} from "../../utils/urlProjectStripeConfig";
import remoteConfig from "../../config";

const Wrapper = styled.div`
  display: flex;
`

const StripeHTML = ({ t }) => {
    const appConfig = remoteConfig();
    const urlParams = useUrlParams();
    
    const lngPropsUrl = {...urlParams}.lng;
    useEffect(() => {
      if (supportedLang.includes(lngPropsUrl)) i18n.changeLanguage(lngPropsUrl);
      else i18n.changeLanguage("en");
    }, [lngPropsUrl]);

    const stripeKey = () => {
        const stripeVal = urlParams.stripe;
        if (!stripeVal) {
            return urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_public;
        }

        if (stripeVal === "live") {
            return urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_public;
        }

        if (stripeVal === "test") {
            return urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_test;
        }
    };

    const onPriceSubmit = ({donation, currency, mode}) => {
        const description = (urlParams && urlParams.description) || appConfig.description;
        axios.post('https://donationwidget.firebaseapp.com/createCheckout', {
            amount: donation * 100,
            mode: mode,
            currency: currency || "eur",
            stripeKey: stripeKey(),
            description: description,
            success_url: urlParams.success_url || appConfig.success_url,
            cancel_url: urlParams.cancel_url || appConfig.cancel_url,
            submitType: 'donate',
            // tips: tips,
            ...urlParams,
            name: urlParams ? urlParams.subtitle : 'Centrum "Razom"'
        }).then(response => {
            // console.log(">>>", response.data)
            // setCheckoutId(response.data.id)
            stripe().redirectToCheckout({sessionId: response.data.id})
        }).catch(e => console.error(e.message))
    }
    return (
        <Wrapper className="globalContent">
          <DonationFormV2 onPriceSubmit={onPriceSubmit} {...urlParams}/>
        </Wrapper>
    )
}


export default withNamespaces()(StripeHTML);
