import * as React from 'react';
import {useEffect} from 'react';
import './App.css';
import styled from "styled-components";
import StripeHTML from "./components/StripeElementsHTML/index";
import useUrlParams from "./utils/useURLParams";

const Wrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: auto;
  background: black url(https://static.tildacdn.com/tild3864-3032-4331-a365-666234396362/photo_2020-08-13_200.png)  ;


*{
    box-sizing: border-box;
}

@media screen and (max-width: 600px) {
    body{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    h1, h2{
        font-size: 1.5rem !important;
    }
}


.donation-module ul{
  display: flex;
}
.donation-module .a1{
  transition: all 0.3s ease-out;
}

.donation-module .hidden{
  opacity: 0;
  transform: translate(0, -40px);
}

.donation-module .a1{
  position: absolute;
  /*left: 0;*/
}

.donation-module .setup-price button{
  margin: 0 5px;
}

.donation-module .submit-btn span:before{
}
.donation-module .cancel-btn span:before{
  content: '<';
}

.donation-module .cancel-btn {
  padding: 0 5px;
}

.donation-module .confirm-btn span:before{
  content: 'Пожертвовать ';
}

.btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  padding: 20px 60px;
  min-height: 60px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
}

.setup-price{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.setup-price input {
  height: 60px;
  font-size: 30px;
  text-align: center;
  z-index: 1;
}
.setup-price .container {
  display: flex;
  padding-bottom: 5px;
}

.btn span {
  position: relative;
  z-index: 1;
}


.link {
  font-size: 20px;
  margin-top: 30px;
}

.link a {
  color: #000;
  font-size: 25px;
}

.setup-price{
  display: flex;
}

.cancel-btn{
  min-width: 50px;
}

.caption{
  color: white;
  padding-bottom: 4px;
}
.caption span{
  padding-bottom: 4px;
}
`



function App({ t }) {
    const url = useUrlParams();
    useEffect(() => {
    }, [url])
    return (
      <StripeHTML/>
    );
}

export default App;
