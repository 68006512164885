export const donationrazominfoConfig = {
    firebaseConfig: {
        apiKey: "AIzaSyBoyz_xP09JXOkyjHyKZh3TK4ReG-bOyuE",
        authDomain: "razominfo-224c5.firebaseapp.com",
        projectId: "razominfo-224c5",
        storageBucket: "razominfo-224c5.appspot.com",
        messagingSenderId: "543387878741",
        appId: "1:543387878741:web:36227a2d71bfe8ca955bdc",
        measurementId: "G-E0HEXXEM7H"
    },
    description: 'Centrum "Razom"',
    success_url: 'https://razom.info/thank-you',
    cancel_url: 'https://razom.info/',
    currencies: [
        ['eur', "curr_eur", '€'],
        ['pln', "curr_pln", 'Zł'],
        ['usd', "curr_usd", '$'],
        ['gbp', "curr_gbp", '£'],
        ['chf', "curr_chf", '₣'],
        ['aud', "curr_aud", 'AU$'],
        ['cad', "curr_cad", 'CA$']
    ],
    gratuities: [
        [0, "donation_for_team_option_none_gratuity"],
        [10, "10%"],
        [15, "15%"],
        [20, "20%"],
    ],
    definedPrices: [20, 50, 100, 200, 300, 500],
    minimalPrice: 20,
};

export default donationrazominfoConfig;