export const urlProjectStripeConfig = [
    {
        url: "localhost",
        stripe_public: "pk_live_51LuipzA5abT9l9tSjJrMj3v9HaTpHxjweeOXxCQ0Yf1AMkS8x7DnBVzoNNLhvbYMBBPMZpz72ZpR2SbiwCT1Samx00PY6NBdDX",
        stripe_test: "pk_test_51LuipzA5abT9l9tSdGRXScX29Z6Ja9KsSfLZDC3CJCYbwb7gPG6MW2IPBePOrt6hwXWKIjRXK9SElzPWe2pRjtTJ00zQDw2z9m"
    },
    {
        url: "donation.razom.info",
        stripe_public: "pk_live_51KeHaQISCnuH1fbtsKmzpVKVDXs7WiMZYaRxsmmuRxqDJtdw8RO6AZURTKL6OwmIN21LP1jFkhtYdGMmamt6GFIx00bHITReZt",
        stripe_test: "pk_test_51KeHaQISCnuH1fbtUciLporeJFSzPbe4XA15w9T2Keb7ISR6xp8YMPyc7Mja6Rgq1pyNHxlwKhH0r65Y0Q3aAiQH00dNVHUwmy"
    },
    {
        url: "s.foundationunion.org",
        stripe_public: "pk_live_51LuipzA5abT9l9tSjJrMj3v9HaTpHxjweeOXxCQ0Yf1AMkS8x7DnBVzoNNLhvbYMBBPMZpz72ZpR2SbiwCT1Samx00PY6NBdDX",
        stripe_test: "pk_test_51LuipzA5abT9l9tSdGRXScX29Z6Ja9KsSfLZDC3CJCYbwb7gPG6MW2IPBePOrt6hwXWKIjRXK9SElzPWe2pRjtTJ00zQDw2z9m"
    },
    {
        url: "s1.foundationunion.org",
        stripe_public: "pk_live_51LuipzA5abT9l9tSjJrMj3v9HaTpHxjweeOXxCQ0Yf1AMkS8x7DnBVzoNNLhvbYMBBPMZpz72ZpR2SbiwCT1Samx00PY6NBdDX",
        stripe_test: "pk_test_51LuipzA5abT9l9tSdGRXScX29Z6Ja9KsSfLZDC3CJCYbwb7gPG6MW2IPBePOrt6hwXWKIjRXK9SElzPWe2pRjtTJ00zQDw2z9m"
    }
];