// import Stripe from 'stripe'
import axios from 'axios'
import {urlProjectStripeConfig} from "../../utils/urlProjectStripeConfig";

export const stripe = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const stripeVal = searchParams.get("stripe");

    if (!stripeVal) {
        return window.Stripe(urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_public);
    }

    if (stripeVal === "live") {
        return window.Stripe(urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_public)
    }

    if (stripeVal === "test") {
        return window.Stripe(urlProjectStripeConfig.find((e) => e.url === window.location.hostname).stripe_test)
    }
};

export function registerElements(elements, exampleName, onPaymentApprove) {
    const formClass = '.' + exampleName;
    const example = document.querySelector(formClass);

    const form = example.querySelector('form');
    const resetButton = example.querySelector('a.reset');
    const error = form.querySelector('.error');
    const errorMessage = error.querySelector('.message');

    function enableInputs() {
        Array.prototype.forEach.call(
            form.querySelectorAll(
                "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
                input.removeAttribute('disabled');
            }
        );
    }

    function disableInputs() {
        Array.prototype.forEach.call(
            form.querySelectorAll(
                "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
                input.setAttribute('disabled', 'true');
            }
        );
    }

    function triggerBrowserValidation() {
        // The only way to trigger HTML5 form validation UI is to fake a user submit
        // event.
        const submit = document.createElement('input');
        submit.type = 'submit';
        submit.style.display = 'none';
        form.appendChild(submit);
        submit.click();
        submit.remove();
    }

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach(function(element, idx) {
        element.on('change', function(event) {
            if (event.error) {
                error.classList.add('visible');
                savedErrors[idx] = event.error.message;
                errorMessage.innerText = event.error.message;
            } else {
                savedErrors[idx] = null;

                // Loop over the saved errors and find the first one, if any.
                var nextError = Object.keys(savedErrors)
                    .sort()
                    .reduce(function(maybeFoundError, key) {
                        return maybeFoundError || savedErrors[key];
                    }, null);

                if (nextError) {
                    // Now that they've fixed the current error, show another one.
                    errorMessage.innerText = nextError;
                } else {
                    // The user fixed the last error; no more errors.
                    error.classList.remove('visible');
                }
            }
        });
    });

    // Listen on the form's 'submit' handler...
    form.addEventListener('submit', function(e) {
        e.preventDefault();

        // Trigger HTML5 validation UI on the form if any of the inputs fail
        // validation.
        var plainInputsValid = true;
        Array.prototype.forEach.call(form.querySelectorAll('input'), function(
            input
        ) {
            if (input.checkValidity && !input.checkValidity()) {
                plainInputsValid = false;
                return;
            }
        });
        if (!plainInputsValid) {
            triggerBrowserValidation();
            return;
        }

        // Show a loading screen...
        example.classList.add('submitting');

        // Disable all inputs.
        disableInputs();

        // Gather additional customer data we may have collected in our form.
        const name = form.querySelector('#' + exampleName + '-name');
        const address1 = form.querySelector('#' + exampleName + '-address');
        const city = form.querySelector('#' + exampleName + '-city');
        const state = form.querySelector('#' + exampleName + '-state');
        const zip = form.querySelector('#' + exampleName + '-zip');
        const additionalData = {
            name: name ? name.value : undefined,
            address_line1: address1 ? address1.value : undefined,
            address_city: city ? city.value : undefined,
            address_state: state ? state.value : undefined,
            address_zip: zip ? zip.value : undefined,
        };

        // Use Stripe.js to create a token. We only need to pass in one Element
        // from the Element group in order to create a token. We can also pass
        // in the additional customer data we collected in our form.
        stripe().createToken(elements[0], additionalData).then(async function(result) {
            try{
                await onPaymentApprove(result)
            }catch (e){

            }

            // Stop loading!


            if (result.token) {
                // If we received a token, show the token ID.
                example.querySelector('.token').innerText = result.token.id;
                example.classList.add('submitted');
            } else {
                // Otherwise, un-disable inputs.
                enableInputs();
            }
        });
    });

    resetButton.addEventListener('click', function(e) {
        e.preventDefault();
        // Resetting the form (instead of setting the value to `''` for each input)
        // helps us clear webkit autofill styles.
        form.reset();

        // Clear each Element.
        elements.forEach(function(element) {
            element.clear();
        });

        // Reset error state as well.
        error.classList.remove('visible');

        // Resetting the form does not un-disable inputs, so we need to do it separately:
        enableInputs();
        example.classList.remove('submitted');
        example.classList.remove('submitting');
    });
}
