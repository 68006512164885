import {useState, useEffect} from 'react';

const useUrlParams = () => {
    const [urlParams, setParams] = useState(null);
    useEffect(() => {
        try {
            const search = window.location.search.substring(1);

            let params = [];
            search.split("?").forEach((param) => {
              params.push(JSON.parse(
                '{"' + decodeURI(param).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'
              ));
            });
            
            setParams(Object.assign(...params));
        } catch (e){
            setParams([])
        }

    }, [])
    return urlParams;
}

export default useUrlParams;
