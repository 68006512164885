import localhostConfig from "./localhost-config";
import donationrazominfoConfig from "./donationrazominfo-config";
import sfoundationunionorgConfig from "./sfoundationunionorg-config";

export const remoteConfig = () => {
    const hostname = window.location.hostname;

    switch (hostname) {
        case "localhost": {
            return localhostConfig;
        }

        case "donation.razom.info": {
            return donationrazominfoConfig;
        }

        case "s.foundationunion.org": {
            return sfoundationunionorgConfig;
        }

        default: {
            return localhostConfig;
        }
    }
}

export default remoteConfig;