import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from './translates/en.json';
import translationPL from './translates/pl.json';
import translationUA from './translates/ua.json';
import translationRU from './translates/ru.json';

export const supportedLang = [
    "en",    
    "pl",
    "ua",
    "ru"    
];

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    pl: {
        translation: translationPL
    },
    ua: {
        translation: translationUA
    },
    ru: {
        translation: translationRU
    }
};

fetch(`https://us-central1-localeyez-ac05f.cloudfunctions.net/api/v3/lang-config/${window.location.hostname}/list?mode=prod`)
.then(res => res.json())
.then(json => {
    // console.log(JSON.stringify(json.languages.find((e) => e.locale === "ru")));
    resources.en.translation = json.languages.find((e) => e.locale === "en");
    resources.pl.translation = json.languages.find((e) => e.locale === "pl");
    resources.ua.translation = json.languages.find((e) => e.locale === "ua");
    resources.ru.translation = json.languages.find((e) => e.locale === "ru");
    
    i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .reloadResources(resources)
});

i18n
.use(reactI18nextModule) // passes i18n down to react-i18next
.init({
    debug: false,
    resources,
    lng: supportedLang,
    fallbackLng: "en",
    simplifyPluralSuffix: false,
    react: {
        wait: true,
    }
});

export default i18n;